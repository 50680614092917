.google-style-text {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
}

.g {
    color: #db4437;
}

.o {
    color: #f4b400;
}

.l {
    color: #0f9d58;
}

.e {
    color: #db4437;
}

.dash {
    color: #4285f4;
}

.style {
    color: #f4b400;
}

.t {
    color: #0f9d58;
}

.y {
    color: #db4437;
}

.text {
    color: #000000;
    border-right: 2px solid #000000;
    animation: typing 1s steps(20) infinite;
}

@keyframes typing {
    0% {
        border-right: 2px solid #000000;
    }

    50% {
        border-right: 2px solid transparent;
    }

    100% {
        border-right: 2px solid #000000;
    }
}