.images-content {
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: hidden;
  justify-content: space-between;
}
.images--card {
  width: 350px;
  height: 280px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.images--img-container {
  height: 200px;
  overflow: hidden;
  width: 95%;
  background: #eee;
  margin: auto;
}

.images--img-container:hover {
  box-shadow: 2px 2px 10px #ddd;
}

.images--img-container img {
  height: auto;
  width: 100%;
}
.images--text-container {
  text-align: left;
  margin-left: 10px;
  color: #333;
  text-decoration: none;
  font-size: 15px;
}
.images--name {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}
.images--link {
  font-size: 14px;
  margin: 0;
  color: #777;
}

@media screen and (max-width: 810px) {
  .images--card {
    width: 320px;
  }
  .images-content {
    justify-content: center;
  }
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1 0 auto;
}
